var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[_c('section',{staticClass:"left"},[_c('div',{staticClass:"login_outer"},[_c('div',{staticClass:"login_inner"},[_vm._m(0),(_vm.form.errorMsg)?_c('vs-alert',{attrs:{"color":"danger","gradient":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("發生錯誤，請稍候再試 😵")]},proxy:true}],null,false,1444274917)},[_vm._v(" "+_vm._s(_vm.form.errorMsg)+" ")]):_vm._e(),_c('div',[_c('ValidationObserver',{staticClass:"space-y-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"電子信箱","rules":("" + (_vm.required ? 'required|email' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"email","placeholder":"電子信箱"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"email"}})]},proxy:true}],null,true),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"密碼","rules":("" + (_vm.required ? 'required|password' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"密碼","visiblePassword":_vm.form.hasVisiblePassword},on:{"click-icon":function($event){_vm.form.hasVisiblePassword = !_vm.form.hasVisiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.form.password === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.form.hasVisiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"再次確認密碼","rules":("" + (_vm.required ? 'required|confirmed:密碼' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"再次確認密碼","visiblePassword":_vm.form.hasVisiblePassword},on:{"click-icon":function($event){_vm.form.hasVisiblePassword = !_vm.form.hasVisiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.form.confirmPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.form.hasVisiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"姓名","rules":("" + (_vm.required ? 'required|max:20' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"placeholder":"姓名"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticClass:"xs",staticStyle:{"stroke":"black"},attrs:{"icon-class":"account"}})]},proxy:true}],null,true),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"手機號碼","rules":("" + (_vm.required ? 'required|TW_phoneNum' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"placeholder":"手機號碼"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"mobile"}})]},proxy:true}],null,true),model:{value:(_vm.form.phoneNum),callback:function ($$v) {_vm.$set(_vm.form, "phoneNum", $$v)},expression:"form.phoneNum"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"商店名稱","rules":("" + (_vm.required ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"placeholder":"商店名稱 (註冊後可隨時更改)"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register(invalid)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"store"}})]},proxy:true}],null,true),model:{value:(_vm.form.storeName),callback:function ($$v) {_vm.$set(_vm.form, "storeName", $$v)},expression:"form.storeName"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"tag":"div","name":"業務代碼","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"placeholder":"業務代碼"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.register(invalid)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticClass:"sm",staticStyle:{"fill":"black"},attrs:{"icon-class":"member"}})]},proxy:true}],null,true),model:{value:(_vm.form.sid),callback:function ($$v) {_vm.$set(_vm.form, "sid", $$v)},expression:"form.sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"text-sm flex items-start justify-between"},[_c('div',{staticClass:"space-x-5 text-gray-text_light"},[_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("已是商家？ 登入")])],1),_c('vs-button',{ref:"register",staticClass:"font-medium",staticStyle:{"border-radius":"12px"},attrs:{"disabled":invalid,"size":"xl","color":"success"},on:{"click":function($event){return _vm.register(invalid)}}},[_vm._v("註冊")])],1)]}}])})],1)],1)])]),_c('section',{staticClass:"right"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v("註冊")])])}]

export { render, staticRenderFns }